import React from "react";
import SearchBox from "./SearchBox";
import { IconSearch } from "@tabler/icons-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { buildSearchBox } from "@coveo/headless";
import { createSearchEngine } from "../../../coveo/utils/createEngine";

export interface GlobalSearchStandaloneProps {
  readonly accessToken: string;
  readonly organizationId: string;
  readonly variant: "popover" | "inline";
}

const closeMobileMenu = () => {
  const closeButton = document.querySelector<HTMLButtonElement>(
    ".navbar-v2-mobile-close-button button",
  );

  if (closeButton) {
    closeButton.click();
  }
};

export const GlobalSearchStandalone = ({
  accessToken,
  organizationId,
  variant,
}: GlobalSearchStandaloneProps) => {
  const [open, setOpen] = React.useState(false);

  const onSearchSubmit = (value: string) => {
    closeMobileMenu();
    setOpen(false);
    window.location.href = `/global-search#q=${value}`;
  };

  const searchEngine = createSearchEngine(
    organizationId,
    accessToken,
    "Global Search",
    "GlobalSearch",
  );
  const searchBoxController = buildSearchBox(searchEngine);

  return (
    <div>
      {variant === "inline" ? (
        <SearchBox
          controller={searchBoxController}
          onSearchSubmit={onSearchSubmit}
        />
      ) : (
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <span className="flex items-end cursor-pointer">
              <IconSearch className="navbar-v2__utility-link" />
            </span>
          </PopoverTrigger>
          <PopoverContent
            className="tw-popover p-0 mt-5 outline-0 rounded-lg"
            side="bottom"
            align="end"
          >
            <SearchBox
              controller={searchBoxController}
              onSearchSubmit={onSearchSubmit}
            />
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
};

export default GlobalSearchStandalone;
