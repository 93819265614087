import React, { useEffect, useState, FunctionComponent } from "react";
import { SearchBox as SearchBoxController } from "@coveo/headless";
import { AutoComplete } from "./AutoComplete";

interface SearchBoxProps {
  controller: SearchBoxController;
  onSearchSubmit: (value: string) => void;
}

const SearchBox: FunctionComponent<SearchBoxProps> = ({
  controller,
  onSearchSubmit,
}) => {
  const [state, setState] = useState(controller.state);

  useEffect(() => {
    controller.showSuggestions();
    return controller.subscribe(() => setState(controller.state));
  }, [controller]);

  return (
    <AutoComplete
      value={state.value}
      onChange={(value) => controller.updateText(value)}
      suggestions={state.suggestions.map((item) => item.rawValue)}
      onSubmit={onSearchSubmit}
    />
  );
};

export default SearchBox;
