import { IconShoppingCart, IconUser } from "@tabler/icons-react";
import React from "react";
import {
  GlobalSearchStandalone,
  GlobalSearchStandaloneProps,
} from "../global-search/GlobalSearchStandalone";

interface UtilityLinksProps {
  isLoggedin: boolean;
  cartItemCount: number;
  globalSearchProps: GlobalSearchStandaloneProps;
  cartUrl: string;
  userSessionUrl: string;
}

const UtilityLinks = ({
  isLoggedin,
  cartItemCount,
  userSessionUrl,
  cartUrl,
  globalSearchProps,
}: UtilityLinksProps) => {
  return (
    <div className="navbar-v2__utility-links">
      <GlobalSearchStandalone {...globalSearchProps} />
      <a href={cartUrl} className="navbar-v2__utility-link relative">
        <IconShoppingCart width={32} />
        <span className={`absolute top-0 end-0 inline-flex items-center py-0.5 px-1.5 rounded-full text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white utility-cart-badge ${cartItemCount === 0 && "hidden"}`}>
          {cartItemCount}
        </span>
      </a>
      {!isLoggedin && (
        <a href={userSessionUrl} className="navbar-v2__utility-link">
          <IconUser width={32} />
          <span className="sr-only">Sign In</span>
        </a>
      )}
    </div>
  );
};

export default UtilityLinks;
