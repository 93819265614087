import React, { useState, useEffect } from 'react'
import moment from 'moment'
import I18n from '../../helpers/I18n'
import { preventNegative } from '../../helpers/Numbers'

interface BaseProps {
  readonly showSeconds: boolean
  readonly onlySeconds?: boolean
  readonly callBack?: () => void
}

interface PropsWithDuration extends BaseProps {
  readonly durationInSeconds: number
  readonly eventStartTime?: never
}

interface PropsWithStartTime extends BaseProps {
  readonly eventStartTime: string // required format for date and time '2020-12-25 08:30:00'
  readonly durationInSeconds?: never
}

type Props = PropsWithDuration | PropsWithStartTime

const CountDownTimer: React.FunctionComponent<Props> = ({
  eventStartTime,
  durationInSeconds,
  callBack,
  showSeconds = false,
  onlySeconds = false,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [days, setDays] = useState<number>(0)
  const [hours, setHours] = useState<number>(0)
  const [minutes, setMinutes] = useState<number>(0)
  const [seconds, setSeconds] = useState<number>(0)
  const [eventTimeFromDuration] = useState(moment().add(durationInSeconds, 'seconds').unix())

  const currentTime = moment().unix()
  const eventTime = eventStartTime ? moment(eventStartTime).unix() : eventTimeFromDuration
  const interval = 1000

  const getInitialDiffTime = () => {
    if (durationInSeconds) {
      return durationInSeconds
    } else if (eventStartTime) {
      return eventTime - currentTime
    } else {
      return 0
    }
  }

  const [diffTime, setDiffTime] = useState(getInitialDiffTime())

  let duration = moment.duration(diffTime * 1000, 'milliseconds')

  const setTimer = (): void => {
    duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds')

    if (onlySeconds) {
      const totalSeconds = preventNegative(duration.asSeconds())
      setSeconds(totalSeconds)
    } else {
      const days = preventNegative(Math.floor(moment.duration(duration).asDays())),
        hours = preventNegative(moment.duration(duration).hours()),
        minutes = preventNegative(moment.duration(duration).minutes()),
        seconds = preventNegative(moment.duration(duration).seconds())

      setDays(days)
      setHours(hours)
      setMinutes(minutes)
      setSeconds(seconds)
    }

    const updatedCurrentTime = moment(moment()).unix()
    setDiffTime(eventTime - updatedCurrentTime)
  }

  useEffect(() => {
    let intervalHandler: number

    if (diffTime > 0) {
      setTimer()
      intervalHandler = window.setInterval(setTimer, interval)
    } else if (diffTime <= 0 && callBack) {
      callBack()
    }

    setIsLoaded(true)
    return () => clearInterval(intervalHandler)
  }, [diffTime])

  return (
    <div className={`countdown-timer ${isLoaded ? 'countdown-timer-loaded' : ''}`}>
      {!onlySeconds && (
        <>
          <div className='countdown-timer-item'>
            <div className='countdown-timer-item-number'>{days}</div>
            {I18n.t('datetime.units.days')}
          </div>
          <div className='countdown-timer-item'>
            <div className='countdown-timer-item-number'>{hours}</div>
            {I18n.t('datetime.units.hours')}
          </div>
          <div className='countdown-timer-item'>
            <div className='countdown-timer-item-number'>{minutes}</div>
            {I18n.t('datetime.units.minutes')}
          </div>
        </>
      )}
      {(onlySeconds || showSeconds) && (
        <div className='countdown-timer-item'>
          <div className='countdown-timer-item-number'>{seconds}</div>
          {I18n.t('datetime.units.seconds')}
        </div>
      )}
    </div>
  )
}

export default CountDownTimer
