import React, { useRef } from "react";
import { IconSearch, IconX } from "@tabler/icons-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";

interface AutoCompleteProps {
  placeholder?: string;
  value: string;
  suggestions: string[];
  onSubmit: (value: string) => void;
  onChange: (value: string) => void;
  variant?: "compact" | "full-width";
}

const UNSELECTED_INDEX = -1;

export const AutoComplete: React.FC<AutoCompleteProps> = ({
  placeholder,
  value,
  suggestions,
  onSubmit,
  onChange,
  variant = "compact",
}) => {
  const [isSuggestionClicked, setIsSuggestionClicked] = React.useState(false);
  const [showSuggestions, setShowSuggestions] = React.useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] =
    React.useState(UNSELECTED_INDEX);
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        setSelectedSuggestionIndex((prev) =>
          Math.max(prev - 1, UNSELECTED_INDEX),
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedSuggestionIndex((prev) =>
          Math.min(prev + 1, suggestions.length - 1),
        );
        break;
      case "Enter":
        if (selectedSuggestionIndex >= 0) {
          onSubmit(suggestions[selectedSuggestionIndex]);
        } else {
          onSubmit(value);
        }
        break;
      case "Escape":
        setShowSuggestions(false);
        inputRef.current?.blur();
        break;
    }
  };

  return (
    <div className="relative">
      <div className="relative w-full">
        {variant === "compact" && (
          <IconSearch className="absolute left-3 top-3 w-5 h-5 text-gray-500" />
        )}
        <input
          ref={inputRef}
          type="text"
          value={value}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => {
            setShowSuggestions(true);
          }}
          onBlur={() => {
            if (!isSuggestionClicked) {
              setShowSuggestions(false);
            }
          }}
          className={cn(
            "tw-input flex !outline-0 rounded-lg appearance-none bg-white border-solid focus:border-gray-300 text-gray-900 w-full py-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white h-12 text-lg",
            variant === "compact"
              ? "pl-10 border-0 border-b border-gray-100"
              : "pr-[72px] border border-gray-300 focus:bg-white focus:ring-1 focus:!outline-2 focus:shadow-custom-hover",
            suggestions.length > 0 && showSuggestions && "rounded-b-none",
          )}
        />
        {value && (
          <IconX
            className={cn(
              "absolute  top-3 w-5 h-5 text-gray-500 cursor-pointer",
              variant === "compact" ? "right-3" : "right-16",
            )}
            onClick={() => {
              onChange("");
              inputRef.current?.focus();
            }}
          />
        )}
        {variant === "full-width" && (
          <Button
            variant="outline"
            size="icon"
            className="absolute right-[1px] top-[1px] bottom-[1px] rounded-r-lg rounded-l-none border-t-0 border-r-0 border-b-0"
            onClick={() => onSubmit(value)}
          >
            <IconSearch className="w-5 h-5" />
          </Button>
        )}
      </div>
      <div
        className={cn(
          "absolute top-full left-0 right-0 z-50 rounded-sm border bg-popover text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          variant === "full-width" && "mt-2",
        )}
      >
        <ul
          className="list-none p-0 m-0"
          onMouseDown={() => setIsSuggestionClicked(true)}
          onMouseUp={() => setIsSuggestionClicked(false)}
        >
          {showSuggestions &&
            suggestions.map((suggestion, index) => (
              <li key={suggestion}>
                <Button
                  variant="listItem"
                  size="lg"
                  onClick={() => {
                    setShowSuggestions(false);
                    onSubmit(suggestion);
                  }}
                  data-selected={selectedSuggestionIndex === index}
                  className="p-3 rounded-lg"
                  title={suggestion}
                >
                  <IconSearch className="w-5 h-5 text-gray-500 mr-2 flex-shrink-0" />
                  <span className="text-ellipsis overflow-hidden">
                    {suggestion}
                  </span>
                </Button>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
